@import url('https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,100..900&family=Titillium+Web:wght@300;400;600&display=swap');


.pclp-resume-section {
    margin-bottom: 40px;
}

.pclp-section-title {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 20px 0;
}

.pclp-resume-curso,
.pclp-resume-curso .pclp-paper-value,
.pclp-resume-curso .pclp-paper-foot-value {
    color: #1e0557;
}

.pclp-resume-curso .MuiPaper-root {
    background-color: #eae6f5;
}

.pclp-paper-title {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 16px;
}

.pclp-paper-value {
    font-size: 28px;
    font-weight: 700;
    text-align: center;
}

.pclp-paper-foot {
    margin-top: 8px;
    padding-top: 8px;
    border-top: #C3C3C3 solid 1px;
}

.pclp-paper-foot-value {
    font-size: 18px;
    font-weight: 700;
}

.pclp-paper-tooltip-open {
    color: #C3C3C3;
    margin: 0 0 0 8px;
}

.pcpl-tab-header {
    margin-bottom: 40px;
    p {
        margin: 0;
    }
}

h1 {
    font-family: 'Titillium Web', Arial, Helvetica, sans-serif;
    font-weight: 600;
    font-size: 30px;
    margin-bottom: 0;
}

.pclp-containerTable {
    overflow-x:auto;
}

.pclp-table-investments {
    border-radius: 6px;
    border: #DCDCDD solid 1px;
    th,
    tfoot td {
        background-color: #FCFCFD;
        border-bottom: #DCDCDD solid 1px;
        padding: 8px;
    }
    th {
        font-weight: 500;
        font-size: 13px;
        /*border-left:  #DCDCDD solid 1px;*/
    }
    td {
        padding: 8px;
        border-bottom: #DCDCDD solid 1px;
        font-size: 15px;
    }

    td:nth-of-type(n+3) {
        text-align: center;
    }

    th button {
        /*width: 100%;*/
        padding: 0 0 0 0;
    }
    th button .colArrow {
        display: inline-block;
    }

}

.pclp-highlight {
    font-weight: 700;
}

.pclp-investordata {
    width: 100%;
    td {
        padding: 8px;
        border-bottom: #DCDCDD solid 1px;
        font-size: 15px;
    }

    td:nth-of-type(n+2) {
        font-weight: 700;
    }

    th button {
        /*width: 100%;*/
        padding: 0 0 0 0;
    }
    th button .colArrow {
        display: inline-block;
    }
}



.pclp-btn-excel {
    background-color: #fff !important;
    color: #000 !important;
    border-radius: 4px;
    border: 1px solid #ccc !important;
    font-size: 12px !important;
    padding: 6px 20px !important;
    margin: 4px 0;
    cursor: pointer;
    float: right;
}

.pclp-btn-excel:hover {
    background-color: #000 !important;
    color: #fff !important;
}

.pclp-footer {
    padding: 40px 20px;
    text-align: center;
    font-size: 12px;
}

.pclp-footer div{
    margin-bottom: 12px;
}

@media( max-width: 768px) {

    .pclp-investordata td {
        font-size: 12px;
    }

    .pclp-TabList button {
        font-size: 12px;
        max-width: 33%;
    }

    .pclp-table-investments th,
    .pclp-table-investments td {
        font-size: 12px;
    }

}